import { EditorSDK } from '@wix/platform-editor-sdk';
import { MEMBERS_AREA } from '@wix/app-definition-ids';

interface SantaMembersAppApi {
  refreshMembersAreaApps(): Promise<void>;
}

let editorSDK: EditorSDK;

export const getSantaMembersApi = () => {
  const payload = { appDefinitionId: MEMBERS_AREA };
  const santaMembersApi = editorSDK.application.getPublicAPI('', payload);

  return santaMembersApi as Promise<SantaMembersAppApi>;
};

const getAppApi = () => ({
  refreshMembersAreaApps: async () => {
    const membersEditorApi = await getSantaMembersApi();
    return membersEditorApi.refreshMembersAreaApps();
  },
});

const editorReady = async (_editorSDK: EditorSDK) => {
  editorSDK = _editorSDK;
  await editorSDK.editor.setAppAPI('', getAppApi());
};

const exports_ = {
  refreshApp: () => editorSDK.tpa.app.refreshApp(),
};

export { editorReady, exports_ as exports };
